.containerMessages {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
    height: 100%;
    max-height: 100%;
}

.titleMessages {
    font-weight: 700;
    font-size: 1.3vw;
    letter-spacing: 1px;
    color: #000000;
}

.containerChatsMessages {
    overflow: scroll;
    width: 100%;
    max-height: 700px;
    min-height: 105px;
    margin-top: 3vw;
}





.containerBlocksChat {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100vh;
}

.blockChatsChat {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;
    height: 100%;
    border-right: 1px solid rgba(89, 89, 89, 0.2);
}


.headerChat {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 5.55vw;
    min-height: 5.55vw;
    background-color: #0F1841;
}

.iconBackChat {
    margin-left: 35px;
    cursor: pointer;
}

.containerChatsChat {
    overflow: scroll;
    width: 95%;
    height: 100%;
    padding: 2.5vw 0;
}

.containerChatChat {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100% !important;
    height: 100%;
    background-color: #F5F6FC;
}

.containerImageUserChat {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 2.65vw;
}

.imageUserChat {
    width: 115%;
    height: 115%;
    cursor: pointer;
    object-fit: contain;
}

.nameUserChat {
    margin-left: 1.25vw;
    font-weight: 700;
    font-size: 1.25vw;
    letter-spacing: 1px;
    color: #FFFFFF;
}

.iconOptionsChat {
    margin-right: 2.5vw;
    margin-left: auto;
    cursor: pointer;
}

.containerChat {
    width: 95%;
    height: 100%;
}

.containerMessagesChat {
    overflow: scroll;
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    height: 73vh;
    padding-top: 3vw;
}

.containerFilesChat {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 73vh;
    padding-top: 1vw;
}

.iconCloseChat {
    max-width: 1.25vw;
    max-height: 1.25vw;
    margin-bottom: 3vw;
    margin-left: auto;
}

.imageFile {
    width: 35vw;
    height: 90%;
    margin-right: auto;
    margin-left: auto;
}


.textWritingChat {
    margin-left: 50px;
    margin-top: 5px;
    font-size: 12px;
    color: #FFFFFF;
}

.textNoWritingChat {
    margin-top: 5px;
    font-size: 12px;
    color: #0f1840;

}
