.containerAbout {
    overflow: scroll;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
    height: 100%;
    max-height: 100vh;
}

.textAbout {
    text-align: left;
    margin-top: 0;
}

.titleAbout {
    font-weight: 700;
    font-size: 1.3vw;
    letter-spacing: 1px;
    color: #000000;
}

.containerSpecialitiesAbout {
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;
}