.containerHome {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: scroll;
    width: 90%;
    height: 100%;
    max-height: 100vh;
}

.titleHome {
    margin-top: 30px;
    text-align: left;
    font-weight: 700;
    font-size: 1.5vw;
    letter-spacing: 1px;
    color: #000000;
}

.containerItemHome {
    overflow: scroll;
    width: 100%;
    margin-top: 10px;
    padding-bottom: 50px;
}

.subtitleHome {
    font-weight: bold;
    font-size: 1.3vw;
    text-decoration: underline;
}

.containerAllPreferences {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}

.containerPreferences {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 45%;
}

.containerPreferenceHome {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 15px;
}

.preferenceTextHome {
    font-size: 1.2vw;
}

.containerIconsPreferencesHome {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 15%;
}

.buttonNoChats {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 7vw;
    font-size: 1.2vw;
    border-radius: 15px;
    color: #FFFFFF;
    background-color: #0f1840;
}