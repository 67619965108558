.containerCoaches {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
    height: 100%;
    max-height: 100vh;
}

.titleCoaches {
    font-weight: 700;
    font-size: 1.3vw;
    letter-spacing: 1px;
    color: #000000;
}

.containerItemCoaches {
    overflow: scroll;
    width: 100%;
    margin-top: 50px;
    padding-bottom: 50px;
}