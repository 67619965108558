.containerChats {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    max-height: 100vh;
}

.titleChats {
    font-weight: 700;
    font-size: 1.3vw;
    letter-spacing: 1px;
    color: #000000;
}

.containerItemChats {
    overflow: scroll;
    width: 100%;
    margin-top: 50px;
    padding-bottom: 50px;
}

.floatButtonChats {
    position: absolute;
    right: 100px;
    bottom: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
    width: 60px;
    height: 50px;
    border-radius: 15px;
    background-color: #0f1840;
    color: #FFFFFF;
    font-size: 50px;
}