.containerScheduledSession {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    padding: 0.625vw 2vw;
    border: 1px solid hsl(229, 62%, 15%);
    border-radius: 15px;
    background-color: #dbdbdb;
}

.containerRightScheduledSession {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.titleScheduledSession {
    font-weight: bold;
    font-size: 1vw;
}

.dayScheduledSession {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 1vw;
}

.containerTextScheduledSession {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.iconScheduledSession {
    width: 1.56vw;
}

.textScheduledSession {
    font-size: 1vw;
    margin-top: 5px;

}