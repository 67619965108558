.containerVerifyCode {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    height: 100vh;
    max-height: 100%;
}

.titleVerifyCode {
    font-size: 1.25vw;
    font-weight: bold;
}

.textVerifyCode {
    font-size: 1.10vw;
    font-weight: bold;
}

.containerInputs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 60%;
    height: 12vw;
    margin-top: 40px;
}

.inputVerifyCode {
    width: 20%;
    height: 100%;
    font-size: 12vw;
    text-align: center;
    border-radius: 20px;
    outline: none;
}

.buttonVerifyCode {
    width: 30vw;
    margin-top: 40px;
}