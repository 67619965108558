.containerCloseSesion {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 100px;
    margin-top: auto;
    background-color: #0F1841;
    font-weight: 600;
    font-size: 1vw;
    letter-spacing: 0.75px;
    color: #FFFFFF;
    cursor: pointer;
}

.iconCloseSesion {
    width: 1.05vw;
    height: 1.09vw;
}
