.containerAvatar {
    position: relative;
}

.imageNavBar {
    object-fit: cover;
}

.dotAvatarConnected {
    position: absolute;
    bottom: 0.35vw;
    left: 3.50vw;
    width: 1.2vw;
    height: 1.2vw;
    border-radius: 100px;
    background-color: #16C83D;
}

.dotAvatarDisconnected {
    position: absolute;
    bottom: 0.35vw;
    left: 3.50vw;
    width: 1.2vw;
    height: 1.2vw;
    border-radius: 100px;
    background-color: #D22614;
}

.dotAvatarOcupado {
    position: absolute;
    bottom: 0.35vw;
    left: 3.50vw;
    width: 1.2vw;
    height: 1.2vw;
    border-radius: 100px;
    background-color: #ffbf47;
}

.dotAvatarEdit {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0.30vw;
    left: 3.60vw;
    width: 1.5vw;
    height: 1.5vw;
    border-radius: 100px;
    background-color: #0f1840;
}
