.containerMenu {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 50px;
}

.MenuItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    border-radius: 8px;
    background-color: #0F1841;
    margin-top: 17px;
    cursor: pointer;
}

.MenuItemNoFocus {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #F2F4F6;
    background-color: #FFFFFF;
    cursor: pointer;
}

.iconMenuItem {
    width: 1.4vw;
    margin-left: 18px;
}

.textMenuItemFocus {
    text-align: left;
    margin-right: auto;
    margin-left: 20px;
    font-weight: 600;
    font-size: 0.95vw;
    letter-spacing: 0.75px;
    color: #FFFFFF;
}

.textMenuItemNoFocus {
    text-align: left;
    margin-right: auto;
    margin-left: 20px;
    font-weight: 600;
    font-size: 0.95vw;
    letter-spacing: 0.75px;
    color: #526581;
}
