.containerProfile {
    overflow: scroll;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
    height: 100vh;
    max-height: 100%;
}

.titleProfile {
    font-weight: 700;
    font-size: 1.3vw;
    letter-spacing: 1px;
    color: #000000;
}

.containerContentProfile {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.containerImageProfile {
    position: relative;
}

.containerImageProfile2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 9vw;
    height: 9vw;
    border-radius: 100px;
    filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.25));
    cursor: pointer;
}

.imageProfile {
    width: 100%;
    height: 100%;
    border-radius: 100px;
    object-fit: cover;
}

.buttonEditImageProfileOnline {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    top: 7vw;
    right: 0.7vw;
    width: 2.1vw;
    height: 2.1vw;
    border-radius: 100px;
    border: none;
    background-color: #16C83D;
    cursor: pointer;
}

.buttonEditImageProfileOffline {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    top: 7vw;
    right: 0.7vw;
    width: 2.1vw;
    height: 2.1vw;
    border-radius: 100px;
    border: none;
    background-color: #d22614;
    cursor: pointer;
}

.buttonEditImageProfileBussy {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    top: 7vw;
    right: 0.7vw;
    width: 2.1vw;
    height: 2.1vw;
    border-radius: 100px;
    border: none;
    background-color: #ffbf47;
    cursor: pointer;
}

.iconEdit {
    width: 0.75vw;
    height: 0.75vw;
}

.titleRatingProfile {
    margin: 0.70vw 0 0.96vw 0;
    font-weight: 700;
    font-size: 1.25vw;
    letter-spacing: 1px;
    color: #526581;
}

.rateProfile {
    color: #0F1841;
    font-size: 1.37vw;
}

.containerDataProfile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.titleDataProfile {
    margin: 1.30vw 0 0.89vw 0;
    font-weight: 700;
    font-size: 1.25vw;
    letter-spacing: 1px;
    color: #526581;
}

.nameProfile {
    margin: 0;
    font-weight: 400;
    font-size: 1vw;
    letter-spacing: 0.75px;
    color: #526581;
}

.containerSpecialitiesProfile {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 22px;
    width: 100%;
}

.textareaProfile {
    width: 95%;
    height: 7.15vw;
    padding-top: 1.40vw;
    padding-left: 1.15vw;
    border: 0.5px solid #969696;
    border-radius: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    resize: none;
    outline: none;
}

.containerButtonProfile {
    width: 16vw;
    margin: 0 auto 30px auto;
}

.containerRateCoach {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 30px;
}

.nameProfileCoach {
    font-size: 1.5vw;
    margin: 0;
    font-weight: bold;
    color: #526581;
}

.statusCoachProfile {
    font-size: 1vw;
    font-weight: bold;
    color: #526581;
}

.descriptionCoachProfile {
    margin-top: 30px;
    padding: 15px 15px;
    border-radius: 20px;
    background-color: #F4F4F4;
    font-size: 1vw;
    text-align: left;
}

.daysWorkCoachProfile {
    font-size: 1vw;
}

.containerButton {
    width: 17vw;
    margin-left: auto;
}
