.containerWriter {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    border-radius: 10px;
    background-color: #FFFFFF;
}

.inputWriter {
    flex: 1;
    height: 3.5vw;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 0.75vw;
    border: none;
    outline: none;
    border-radius: 0.2vw;
    background-color: #FFFFFF;
    resize: none;
}

.senderWriter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 3.05vw;
    height: 3.5vw;
    margin-left: 0.44vw;
    border-radius: 4px;
    background-color: #FFFFFF;
    cursor: pointer;
}
