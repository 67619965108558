.containerHistoryItem {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-top: 0.594vw;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    background: #FFFFFF;
    cursor: pointer;
}

.containerImageHistoryItem {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 4.75vw;
    height: 4.75vw;
    margin: 0.95vw 0.70vw 0.95vw 1.55vw;
    border-radius: 100px;
    overflow: hidden;
}

.imageHistoryItem {
    width: 115%;
    height: 115%;
    object-fit: contain;
}

.containerTextHistoryItem {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
}

.nameHistoryItem {
    margin: 0;
    font-weight: 700;
    font-size: 1.25vw;
    letter-spacing: 1px;
    color: #000000;
}

.companyHistoryItem {
    margin-top: 0.594vw;
    margin-bottom: 0.4vw;
    font-weight: 400;
    font-size: 1vw;
    letter-spacing: 0.75px;
    color: #000000;
}

.dateHistoryItem {
    margin-right: 1.45vw;
    font-weight: 400;
    font-size: 1vw;
    letter-spacing: 0.75px;
    color: #969696;
}
