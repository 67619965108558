.button {
    width: 100%;
    height: 2.65vw;
    margin-top: 30px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 20px;
    background-color: #0F1841;
    font-weight: 600;
    font-size: 0.89vw;
    color: #FFFFFF;
    cursor: pointer;
}

button:disabled {
    background-color: rgba(15, 24, 65, 0.5);
}

.secondaryButton {
    width: 100%;
    height: 2.65vw;
    margin-top: 40px;
    border: 2px solid #0F1841;
    border-radius: 20px;
    background-color: transparent;
    font-weight: 600;
    font-size: 0.89vw;
    color: #0F1841;
    cursor: pointer;
}

.iconButton {
    width: 0.8vw;
    height: 0.75vw;
    margin-right: 0.525vw;
}
