.containerScheduleComponent {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.625vw;
    border: 1px solid #0f1840;
    border-radius: 15px;
    width: 100%;
    background-color: #dbdbdb;
}

.cubeSchedule {
    width: 3.64vw;
    height: 3.54vw;
    margin-right: 2.60vw;
    border-radius: 15px;
    background-color: #0f1840;
}

.textSchedule {
    font-size: 1.2vw;
}