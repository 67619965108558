.containerSpecialityBig {
    width: 21%;
    padding: 7px 10px;
    border-radius: 20px;
    border: none;
    background-color: #0f1840;
    color: #FFFFFF;
    text-align: left;
}

.titleSpecialityBig {
    margin: 5px;
    font-size: 1.2vw;
    font-weight: bold;
}

.descriptionSpecialityBig {
    margin: 5px;
    font-size: 1vw;
}