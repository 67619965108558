.containerChatItem {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-top: 0.594vw;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    background: #FFFFFF;
    cursor: pointer;
}

.containerImageChatItem {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 4.75vw;
    height: 4.75vw;
    margin: 0.95vw 0.70vw 0.95vw 1.55vw;
    border-radius: 100px;
}

.imageChatItem {
    width: 115%;
    height: 115%;
    object-fit: contain;
}

.containerTextChatItem {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
    overflow: hidden;
}

.nameChatItem {
    margin: 0;
    font-weight: 700;
    font-size: 1.10vw;
    letter-spacing: 1px;
    color: #000000;
}

.companyChatItem {
    text-align: left;
    margin-top: 0.594vw;
    margin-bottom: 0.4vw;
    font-weight: 400;
    font-size: 1vw;
    letter-spacing: 0.75px;
    color: #000000;
}

.lastMessageChatItem {
    margin: 0;
    max-width: 30vw;
    text-align: left;
    font-weight: 400;
    font-size: 0.89vw;
    letter-spacing: 0.75px;
    color: rgba(89, 89, 89, 0.7);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.containerDetailsChatItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: 1.55vw;
}

.timeChatItem {
    font-weight: 400;
    font-size: 0.80vw;
    letter-spacing: 0.75px;
    color: #969696;
}

.containercenterChatItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 5px 0;
}

.containerSpecialities {
    padding: 3px 8px;
    border-radius: 5px;
    background-color: #efefef;
    font-size: 12px;
}

.containerInitChat {
    padding: 3px 8px;
    border-radius: 5px;
    background-color: #d6ffdf;
    font-size: 12px;
    /*margin-left: 10px;*/
}
